.textarea {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}

label {

}

textarea {
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 10px;
    font-family: Poppins;
    font-size: 12px;
}

textarea:focus,
textarea:active {
    outline: 0;
}