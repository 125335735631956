.title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title h1 {
    font-size: 18px;
    color: var(--primary);
}

.title span {
    display: flex;
    width: 100px;
    height: 3px;
    margin-top: 5px;
    background-color: var(--primary);
}