* {
    box-sizing: border-box;
}

html,
body {
    margin: 0;
    padding:0;
    font-family: Poppins;
}

body {
    --dot-bg: #fff;
    --dot-color: var(--primary);
    --dot-size: 1px;
    --dot-space: 20px;
    background:
        linear-gradient(90deg, var(--dot-bg) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center / var(--dot-space) var(--dot-space),
        linear-gradient(var(--dot-bg) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center / var(--dot-space) var(--dot-space),
        var(--dot-color);
    overflow-x: hidden;
}

:root {
    --primary: #95C29F;
    --primary-hover: #72a27c;
    --border-color: #ededed;
}