.button {
    width: 100px;
    padding: 9px 5px;
    justify-content: center;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    background-color: var(--primary);
    border: 1px solid var(--primary);
    border-radius: 10px;
    cursor: pointer;
    transition: background-color .3s ease-in-out;
}

.button:hover {
    background-color: var(--primary-hover);
}