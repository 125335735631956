.input {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}

label {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 500;
}

input {
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 10px;
    font-family: Poppins;
    font-size: 12px;
}

input:focus,
input:active {
    outline: 0;
}