.page__price-request {
    display: flex;
    width: 100vw;
    height:100%;
    justify-content: center;
}

.page__container {
    width: 100%;
    max-width: 500px;
    height: auto;
    padding: 15px;
    margin: 15px;
    margin-top: 15px;
    justify-content: center;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .05);
}

img {
    display: flex;
    margin: 0 auto;
    margin-bottom: 2.5rem;
}

.title {
    margin-bottom: 3rem;
}

.input,
.radiobutton {
    margin-bottom: 0.5rem;
}

.textarea {
    margin-bottom: 0.75rem;
}

.error {
    margin-bottom: 1.5rem;
}